import React , { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

import logo from '../../assets/fake-data/logo';

const Privacyp = () => {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
        <footer className="TCS">
            <div className="container">
                <div className="row">
                    <div className="footer__body">
                      <Link to="/">
                        <img src={logo} alt="Monteno" data-aos="fade-down" />
                      </Link>
                        
                        <p className="desc fs-18" data-aos="fade-up">
                        Effective Date: January 30, 2023

Pepepal is a decentralized exchange (DEX) that values the privacy of its users. We understand that privacy is a critical issue for our users and we are committed to protecting their personal information.

Pepepal will never share, sell or rent your personal information to third parties. This includes your name, address, email, phone number, or any other information that could be used to identify you.

The only time we may need to access your information is for the purposes of security, troubleshooting, or for compliance with legal obligations. In such cases, we will only access the information that is necessary and will do so in a secure and transparent manner.

Pepepal uses state-of-the-art encryption to ensure that your personal information is secure. We also implement strict security measures to protect against unauthorized access to your information.

Pepepal is built on blockchain technology, which means that your personal information is stored in a decentralized manner. This eliminates the need for a centralized database, making it much more difficult for unauthorized access to occur.

In summary, Pepepal is committed to protecting the privacy of its users. We will never share, sell or rent your personal information to third parties, and we use the latest encryption and security measures to protect your data.

If you have any questions or concerns about our privacy policy, please don't hesitate to contact us. 
                        </p>
                        <ul className="social">
                            <li data-aos="fade-up" data-aos-duration="1000"><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                            <li data-aos="fade-up" data-aos-duration="1200"><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                            <li data-aos="fade-up" data-aos-duration="1400"><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                            <li data-aos="fade-up" data-aos-duration="1600"><Link to="#"><i className="fab fa-youtube"></i></Link></li>
                        </ul>
                    </div>
                    <div className="footer_bottom">
                        <p className="fs-16">Copyright © 2024, PepePal</p>
                        <ul>
                            <li><Link to="/Terms">Terms & Condition</Link></li>
                            <li><Link to="/Privacy">Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        {
            isVisible && 
            <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
        }</>
        
    );
}

export default Privacyp;