import React , { useState } from 'react';
import { Link } from 'react-router-dom'

const Action = () => {

    const [data] = useState(
        {
            title: 'Join us Fren!',
            desc: 'Join our Telegram group and be part of the PEPL community!',
        }
    )

    return (
        <section className="tf-section action">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="action__body" data-aos="fade-up">
                            <div className="block-text">
                                <h3 className="mb-13">{data.title}</h3>
                                <p className="fs-21 mb-7">{data.desc}</p>
                            </div>
                            <a href='https://t.me/pepepalportal/' className="btn-action" target="_blank" rel="noopener noreferrer">
            Join Now
        </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Action;