const dataRoadMap = [
    {
        time: 'August 8 2024',
        title: 'TCC NFT Launch',
        desc: 'Put to market the first 2555 TCC tokens, that will account for the fractional shares of the first 7 bikes in the TCC Network',
        class: 'left'
    },
    {
        time: 'Q3 2024',
        title: 'Share Assignment',
        desc: 'The first 7 custom harleys in the fleet will be assigned to the fractional owners of the TCC network, and the first non refundable rental periods can be scheduled',
        class: 'right mt-223'
    },
    {
        time: 'Q3 2024',
        title: 'Insurance and Expansion',
        desc: 'The rest of 2024 will be spent expanding the fleet and instilling our own self insurance policy, that allows fractional owners to earn additional passive income from shares in their own insurance bond',
        class: 'left mt--23'
    },
    {
        time: 'Late Q1 early Q2 2023',
        title: 'Continued Growth',
        desc: 'Along with more game updates and token listings. Heading into March we will start rolling out our first implementation of PepeGuard, a DeFi security suite to keep investors, users, teams and the community at large safe from scams and fraud, we are taking the steps now to govern ourselves so we will not have to give our freedom to others in the future. "Those who would give up essential Liberty, to purchase a little temporary Safety, deserve neither liberty nor safety." - Benjamin Franklin',
        class: 'right mt-200'
    },
]

export default dataRoadMap;