import React from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import dataCard from '../assets/fake-data/data-card';
import dataFaq from '../assets/fake-data/data-faq';
import dataPartners from '../assets/fake-data/data-Partners';
import dataPortfolio from '../assets/fake-data/data-portfolio';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import dataTeam from '../assets/fake-data/data-team';
import AboutKava from '../components/layouts/AboutKava';
import Action from '../components/layouts/Action';
import FAQ from '../components/layouts/FAQ';
import Partners from '../components/layouts/Partners';
import Portfolio from '../components/layouts/Portfolio';
import RoadMap from '../components/layouts/RoadMap';
import Speciality from '../components/layouts/Speciality';
import Team from '../components/layouts/Team';
import Logohead from '../components/slider/Logohead';
import SliderOne from '../components/slider/SliderOne';
import Chart from '../components/layouts/Chart';
import About from '../components/layouts/About';
import ReactTradingviewWidget from 'react-tradingview-widget';

const Home01 = () => {
    return (
        <div>
            <Header />
            <Logohead />
            <About />
            <FAQ data={dataFaq} />
            <Action />
            <Footer />
        </div>
    );
}

export default Home01;