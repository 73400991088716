import React , { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

import logo from '../../assets/fake-data/logo';

const TCS = () => {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
        <footer className="TCS">
            <div className="container">
                <div className="row">
                    <div className="footer__body">
                      <Link to="/">
                        <img src={logo} alt="Monteno" data-aos="fade-down" />
                      </Link>
                        
                        <p className="desc fs-18" data-aos="fade-up">
                        "The following are the terms and conditions for the use of PepePal, a completely decentralized cryptocurrency ecosystem. These terms and conditions are for informational purposes and should be taken seriously. 

By using PepePal, you acknowledge that the exchange and all PepePal products constitute a dissident platform and that its functionality and content is solely intended to provide alternative economic tools for its users. 

By using PepePal products, you acknowledge that PepePal is not governed or regulated by any governmental body and that any actions taken on the platform can have real-world consequences, including, but not limited to, financial loss. PepePal and its governing body, the DAO, is not liable for any damages incurred through the use of its platform. 

You agree that PepePal is not affiliated with, nor endorsed by, the Securities and Exchange Commission (SEC) or any other government agency/institution. 

You acknowledge that the content on PepePal may be offensive, inappropriate, or otherwise not suitable for some audiences.

You acknowledge that any form of speech, including, but not limited to art, memes, media, or words on PepePal platforms, especially regarding corporations, individuals, government entities, and public figures should be considered parody, coincidental, fictional, and falls under first amendment and fair use protection. 

You agree that PepePal will not be held liable for any losses, damages, or harm that may result from using the platform.

You acknowledge that the terms and conditions of PepePal are subject to change at any time, and it is your responsibility to stay informed of any updates.

By using PepePal, you agree to use the platform at your own risk and take full responsibility for your actions.

You agree that the use of PepePal is for infotainment and alternative investing purposes only, and securities of any form are not transferred or exchanged. 

By using PepePal, you acknowledge that you have read, understood, and agreed to these terms and conditions." 
                        </p>
                        <ul className="social">
                            <li data-aos="fade-up" data-aos-duration="1000"><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                            <li data-aos="fade-up" data-aos-duration="1200"><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                            <li data-aos="fade-up" data-aos-duration="1400"><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                            <li data-aos="fade-up" data-aos-duration="1600"><Link to="#"><i className="fab fa-youtube"></i></Link></li>
                        </ul>
                    </div>
                    <div className="footer_bottom">
                        <p className="fs-16">Copyright © 2024, PepePal</p>
                        <ul>
                            <li><Link to="/Terms">Terms & Condition</Link></li>
                            <li><Link to="/Privacy">Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        {
            isVisible && 
            <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
        }</>
        
    );
}

export default TCS;