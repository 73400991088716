const dataFaq = [
    {
        title: 'What exactly is PepePal?',
        text: 'PepePal is an ERC20 token on the Ethereum network, Its the primary token of the PepePal Eco-system, including PepeX, PepeDex, FrenDAO, PepeGuard, and more. With a fixed supply of 6,666,666 PEPL is an extremely scarce token, but it holds immense utility in DeFi. The Max transaction amount is 66,666. The PEPL Contract address is :0xfDcE70764Ffad176B710616CA4a00a24C96b1E8E',
        show: 'true'
    },
    {
        title: 'Is it a Security?',
        text: 'PEPL is not a Security, it is a Governance token for a Decentralized Autonomous Organization (DAO), Think of it as a memebership certificate for a Digital Country Club that happens to be transferrable on Ethereum. Its a very exclusive club, out of 8 billion people on the planet and growing, PEPLs supply is only 6,666,666.',

    },
    {
        title: 'Why two Exchanges?',
        text: 'The HEX(Hybrid Exchange) is aimed at teaching new traders and users how to effectively use the DEX, and give alt coins a proper entry point to DeFi, and those who want to take advantage of the lower fees and broader liquidity options our DEX offers.',
    },
    {
        title: 'What is the easiest way to buy PEPL?',
        text: 'Right now the easiest way to obtain PEPL if you have never traded crypto before is with FLOOZ, a new DeFi platform that allows you to buy crypto with your credit card, debit card, or bank account. You can also buy PEPL on Uniswap, or any other DEX that supports the token.',
    },
]

export default dataFaq;