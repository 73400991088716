

import img7 from '../images/blog/blog-07.jpg'
import img8 from '../images/blog/blog-08.jpg'
import img9 from '../images/blog/blog-09.jpg'
import img10 from '../images/blog/blog-10.jpg'
import img11 from '../images/blog/blog-11.jpg'
import img12 from '../images/blog/blog-12.jpg'
import img13 from '../images/blog/blog-13.jpg'
import img14 from '../images/blog/blog-14.jpg'
import img15 from '../images/blog/blog-15.jpg'

const dataBlog = [
    {
        img: img7,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img8,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img9,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img10,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img11,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img12,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img13,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img14,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img15,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img7,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img8,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img9,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img10,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img11,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img12,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
]

export default dataBlog;