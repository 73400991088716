import React from 'react';
import { Link } from 'react-router-dom'

import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import dataRoadMap from '../assets/fake-data/data-roadmap';

import mission from '../assets/images/pages/frendao.gif'
import vission from '../assets/images/pages/ancappepe.jpg'
import Action from '../components/layouts/Action';
import RoadMap from '../components/layouts/RoadMap';
import img from '../assets/images/pages/shweet.gif'

const OurMission = () => {
    return (
        <div className='mission'>
            <Header />
            <section className="tf-section page-title">
                <div className="container">
                    <div className="col-md-12">
                        <div className="page-title__body rm">
                            <div className="block-text pt-12">
                                <h2 className="sub-title mb-20">Vision & Mission</h2>
                                <p className="fs-24 mb-33" >PEPL is a DeFi project that was founded on one <br /> simple principle: <br /> Freedom. <br /> By the People. For the People. </p>
                            </div>
                            <img className="s2" src={img} alt="Monteno" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section visions">
                <div className="container">
                    <div className="row reverse">
                        <div className="col-xl-7 col-md-12">
                            <div className="group-image" data-aos="flip-left"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">
                                <img src={mission} alt="Monteno" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text pt-12">
                                <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Our Vision</h5>
                                <h3 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">We're building our own economy, free of goblins and oligarchs. </h3>
                                <p className="fs-18 mb-41" data-aos="fade-up" data-aos-duration="1000"></p>
                                <a href="https://whitepaper.pepepal.com/whitepeper-v1/" className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section missions">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text pt-12">
                                <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Our Mission</h5>
                                <h3 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">Change The Way People Trade.  </h3>
                                <p className="fs-18 mb-29" data-aos="fade-up" data-aos-duration="1000">
                                    A frenlier, comfier crypto economy. </p>
                                <ul data-aos="fade-up" data-aos-duration="1000">
                                    <li><i className="fa fa-check"></i><p className="fs-18">Secure Smart Contracts, governed by the community, in all aspects of the Eco-System. </p></li>
                                    <li><i className="fa fa-check"></i><p className="fs-18">Engaging, Entertaining and Educating DeFi users all at the same time. The only good economy, is one where everyone understands the terms.</p></li>
                                    <li><i className="fa fa-check"></i><p className="fs-18">Freedom from Government Hegemony and the World Economic Forum. </p></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-12">
                            <div className="group-image" data-aos="flip-right"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">
                                <img src={vission} alt="Monteno" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Action />
            <Footer />
        </div>
    );
}

export default OurMission;