import React from 'react';
import { Link } from 'react-router-dom'

const Montono2 = (props) => {

    const data = props.data;

    return (
        <section className="tf-section montono" id='portfolio'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-text center">
                            <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>Listed Projects</span></h1>
                            <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Exchange Partners</h5>
                            <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">Learn More About<br /> Our Frens</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-52">
                    {
                        data.slice(8,15).map((data,index) => (
                            <div key={index} className="col-xl-4 col-md-6">
                                <div className="image-box" data-aos="fade-up" data-aos-duration="1000">
                                    <img src={data.img9} />
                                    <img src={data.img10} />
                                    <img src={data.img11} />
                                    <img src={data.img12} />
                                    <img src={data.img13} />
                                    <img src={data.img14} />
                                    <div className="image-box__title">
                                        <a href="https://www.fennecblockchain.com/" className="h6">{data.title9}</a>
                                        <a href="https://www.memetic.ai/" className="h6">{data.title10}</a>
                                        <a href="https://4chan500.biz/#/" className="h6">{data.title11}</a>
                                        <a href="https://capitol.games/" className="h6">{data.title12}</a>
                                        <a href="https://pepemaruinu.com/" className="h6">{data.title13}</a>
                                        <a href="https://ClownWorld.Finance/" className="h6">{data.title14}</a>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className="col-12">
                        <div className="view-all center">
                            <a href="https://pepex.pepepal.com" className="btn-action" to="https://pepex.pepepal.com">
                                Trade on PepeX
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Montono2;