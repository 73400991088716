import React from 'react';
import { Link } from 'react-router-dom'

import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import dataRoadMap from '../assets/fake-data/data-roadmap';
import dataPartners from '../assets/fake-data/data-Partners';

import mission from '../assets/images/items/psyop.png'
import vission from '../assets/images/pages/our-vission.png'
import Action from '../components/layouts/Action';
import Partners from '../components/layouts/Partners';
import RoadMap from '../components/layouts/RoadMap';
import img from '../assets/images/pages/shweet.gif'

const OurMission = () => {
    return (
        <div className='mission'>
            <Header />
            <section className="tf-section page-title">
                <div className="container">
                    <div className="col-md-12">
                        <div className="page-title__body rm">
                            <div className="block-text pt-12">
                                <h2 className="sub-title mb-20">PepePal (PEPL)</h2>PEPL is a DeFi project that was founded on one simple principle:<br /> Freedom. By the People. For the People.
                                <p className="fs-24 mb-33" ></p>
                            </div>
                            <img className="s2" src={img} alt="Monteno" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section visions">
                <div className="container">
                    <div className="row reverse">
                        <div className="col-xl-7 col-md-12">
                            <div className="group-image" data-aos="flip-left"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">
                                <img src={mission} alt="Monteno" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text pt-12">
                                <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">About Us</h5>
                                <h3 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">Building a Frenlier, Smarter DeFi Community, with dank memes. </h3>
                                <p className="fs-18 mb-41" data-aos="fade-up" data-aos-duration="1000">DeFi got it's foundation in Financial Freedom, Information Freedom and Overall Freedom. We're here to put freedom back into DeFi, by creating a truly decentralized community that has the ability and authority to govern itself. And there's nothing anyone can do to stop it, because it's decentralized. Regulators and Tyrants BTFO. </p>
                                <Link to="/our-mission" className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section missions">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text pt-12">
                                <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">What We're Doing</h5>
                                <h3 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">A Frenlier, Comfier Crypto Economy.</h3>
                                <p className="fs-18 mb-29" data-aos="fade-up" data-aos-duration="1000">
                                    PepePal is making the meme market and DeFi at large more accessible and User Frenly. </p>
                                <ul data-aos="fade-up" data-aos-duration="1000">
                                    <li><i className="fa fa-check"></i><p className="fs-18">PepeX the worlds frenliest and safest HEX </p></li>
                                    <li><i className="fa fa-check"></i><p className="fs-18">PepeDex a Meme centric fully functional DEX with Preds, Lotto, Ponzi and more!  </p></li>
                                    <li><i className="fa fa-check"></i><p className="fs-18">FrenDAO is The governance system run by the community that shapes and grows the PepePal Eco-System.</p></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-12">
                            <div className="group-image" data-aos="flip-right"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">
                                <img src={vission} alt="Monteno" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Action />
            <Footer />
        </div>
    );
}

export default OurMission;