import img1 from '../images/items/item-17.png'
import img2 from '../images/items/item-18.png'
import img3 from '../images/items/item-19.png'
import img4 from '../images/items/item-20.png'
import img5 from '../images/items/item-21.png'
import img6 from '../images/items/item-22.png'
import img7 from '../images/items/item-23.png'


const dataPortfolio = [
    {
        bg: 'bg-2',
        img: img1
    },
    {
        bg: 'bg-1',
        img: img2
    },
    {
        bg: 'bg-2',
        img: img3
    },
    {
        bg: 'bg-1',
        img: img4
    },
    {
        bg: 'bg-2',
        img: img5
    },
    {
        bg: 'bg-1',
        img: img6
    },
    {
        bg: 'bg-2',
        img: img7
    },
]

export default dataPortfolio;