import React, { useState } from 'react';
import logo from '../../assets/images/pages/shweet.gif';  // Adjust the path according to your project structure

const Logohead = () => {
    const [datatext] = useState({
        subtitle: 'PepePal',
        title: 'The frenliest DeFi project since 2021',
        desc: 'Decentralized Exchange and GameFi Platform'
    });

    return (
        <section className="tf-section hero-slider">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-6 col-12">
                        <div className="block-text pt-24">
                            <h6 className="sub-title mb-6" data-aos="fade-up">{datatext.subtitle}</h6>
                            <h2 className="title mb-26" data-aos="fade-up">{datatext.title}</h2>
                            <p className="desc mb-43" data-aos="fade-up">{datatext.desc}</p>
                            <a href="https://app.uniswap.org/#/swap?outputCurrency=0xfdce70764ffad176b710616ca4a00a24c96b1e8e" className="btn-action style-2" data-aos="fade-up" data-aos-duration="1200">Buy Now</a>
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-6 col-12">
                        <div className="content-right d-flex justify-content-center align-items-center">
                            <img src={logo} alt="Logo" className="logo-image" style={{ width: '75%', height: 'auto' }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Logohead;
