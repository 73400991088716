import img1 from '../images/icon/hosting.png'
import img2 from '../images/icon/provision.png'
import img3 from '../images/icon/badge.png'
import img4 from '../images/icon/chat.png'

const dataCard = [
    {
        title1:'PepePal (PEPL)',
        desc: 'The Governance Token for the Frendao, and the primary token of all PEPL Utility.',
        class: 'mr-28',

        class2: 's2',
        img: img1
    },
    {

        title2:'PepeX/PepeDex',
        desc: 'Our Exchanges that make DeFi and Alt-Coins more accessible to the masses.',
        class: 'mt-45',

        class: 's2',
        img: img2
    },
    {

        title3:'PepeGuard',
        desc: 'Our DeFi Insurance Protocol that protects users from the risks of DeFi and Crypto.',
        class: 'mr-28 mt--16',

        class2: 's2-m',
        img: img3
    },
    {
        title4:'GameFi and NFTS',
        desc: 'Will be the next generation of gaming, collectibles and memes.',
        class: 'mt-29',

        class2: 's2',
        img: img4
    },
]

export default dataCard;