import img1 from '../images/team/team-01.jpg'
import img2 from '../images/team/team-02.jpg'
import img3 from '../images/team/team-03.jpg'
import img4 from '../images/team/team-04.jpg'
import img5 from '../images/team/team-05.jpg'
import img6 from '../images/team/team-06.jpg'
import img7 from '../images/team/team-07.jpg'
import img8 from '../images/team/team-08.jpg'
import img9 from '../images/team/team-09.jpg'
import img10 from '../images/team/team-10.jpg'
import img11 from '../images/team/team-11.jpg'
import img12 from '../images/team/team-12.jpg'

const dataTeam = [
    {
        img1: img1,
        name:'Wut Honker',
        position: 'Founder'
    },
    {
        img2: img2,
        name:'Sun God Ra',
        position: 'Business Deverlopment'
    },
    {
        img4: img4,
        name:'Mikei',
        position: 'International Relations'
    },
]

export default dataTeam;