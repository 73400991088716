import React from 'react';
import { Link } from 'react-router-dom';

import './button.scss';

const ButtonOne = () => {
  return (
        <a href='https://t.me/pepepalportal/' className="btn-action" target="_blank" rel="noopener noreferrer">
            Join Now
        </a>
  )
}

export default ButtonOne;