import img1 from '../images/items/montono-01.png'
import img2 from '../images/items/montono-02.png'
import img3 from '../images/items/montono-03.png'
import img4 from '../images/items/montono-04.png'
import img5 from '../images/items/montono-05.png'
import img6 from '../images/items/montono-06.png'
import img7 from '../images/items/montono-07.png'
import img8 from '../images/items/montono-08.png'
import img9 from '../images/items/montono-09.png'
import img10 from '../images/items/montono-10.png'
import img11 from '../images/items/montono-11.png'
import img12 from '../images/items/montono-12.png'
import img13 from '../images/items/montono-13.png'
import img14 from '../images/items/montono-14.png'

const dataMontono = [
    {
        img1: img1,
        title1: 'WhitePaper'
    },
    {
        img2: img2,
        title2: 'LinkTree'
    },
    {
        img3: img3,
        title3: 'PepeX'
    },
    {
        img4: img4,
        title4: 'PepeDex', 
    },
    {
        img5: img5,
        title5: 'FrenDAO'
    },
    {
        img6: img6,
        title6: 'PepeGuard'
    },
    {
        img7: img7,
        title7: 'Trustless'
    },
    {
        img8: img8,
        title8: 'Hotline: PeePee PooPoo'
    },
    {
        img9: img9,
        title9: 'Fennec BlockChain'
    },
    {
        img10: img10,
        title10: 'Memetic/PepeCoin'
    },
    {
        img11: img11,
        title11: '4ch500'
    },
    {
        img12: img12,
        title12: 'CPTL'
    },
    {
        img13: img13,
        title13: 'PepeMaru'
    },
    {
        img14: img14,
        title14: 'ClownWorld'
    },
]

export default dataMontono;