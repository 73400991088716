import React from 'react';
import Header from '../components/header/Header'
import TCS from '../components/footer/TCS'

const Terms = () => {
    return (
        <div>
            <Header />
            <TCS />
        </div>
    );
}

export default Terms;