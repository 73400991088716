import React from 'react';
import Header from '../components/header/Header'
import Privacyp from '../components/footer/Privacyp'

const Privacy = () => {
    return (
        <div>
            <Header />
            <Privacyp />
        </div>
    );
}

export default Privacy;